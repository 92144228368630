import React from "react";
import EnhancedMarkdown from '../../helpers/enhanced-markdown'

class Module extends React.Component {
    render() {
        const { markdown, classes } = this.props;
        return (
            <div className={ 'markdown-container ' + classes }>
                <EnhancedMarkdown>{ markdown }</EnhancedMarkdown>
            </div>
        )
    }
}

export default Module
