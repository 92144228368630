import { useStaticQuery, graphql } from "gatsby"
export const  useReviewsData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesReviewsYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
          reviews {
            content
          }
        }
      }
    `
  )
  return data.pagesReviewsYaml
}