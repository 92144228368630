import React from "react";
import EnhancedMarkdown from '../../helpers/enhanced-markdown'
import Img from "gatsby-image"
import { Link } from "gatsby";

class HeadingModule extends React.Component {
    render() {
        const { headingText, subHeadingText, classes, subHeadingMaxWidth, headingImageAbove, headingImageAboveMaxHeight, backLinkUrl, backLinkText } = this.props;
        return (
            <section className={'heading-module-container container ' + ( classes || '' ) + ( headingImageAbove ? ' heading-image' : '' ) }>
                { headingImageAbove &&
                    <div className="row">
                        <div className="col-100">
                            <Img
                                fluid={ headingImageAbove }
                                objectFit="cover"
                                objectPosition="50% 50%"
                                style={{maxHeight: headingImageAboveMaxHeight || 'auto'}}
                                alt=""
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-100">
                        { headingText && <h1 className="heading-text">{headingText}</h1> }
                        { subHeadingText && <div className="p sub-heading-text" style={{ maxWidth: subHeadingMaxWidth || 800 }}><EnhancedMarkdown>{ subHeadingText }</EnhancedMarkdown></div> }
                    </div>
                </div>
                { backLinkUrl &&
                    <div className="back-link">
                        <Link to={ backLinkUrl }>
                            <span className="icon-chevron-left"></span>
                            { backLinkText || 'Back' }
                        </Link>
                    </div>
                }
            </section>
        )
    }
}

export default HeadingModule
