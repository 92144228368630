import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadingModule from "../components/modules/heading-module"
import MarkdownModule from "../components/modules/html-markdown"
import { useReviewsData } from "../hooks/use-reviews-data"
class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

	const ContentContainer = () => {
		const { heading, reviews } = useReviewsData();
		return (
			<div>
				<HeadingModule
					headingText={ heading.headingText }
					subHeadingText={ heading.subHeadingText }
					subHeadingMaxWidth={ heading.subHeadingMaxWidth}
					classes=""
				/>
				<section className="darker py-5">
					<div className="container">
						<div className="d-none d-small-inline-block col-1-8"></div>
						<div className="col-6-8">
							<MarkdownModule
								markdown={ reviews.content }
								classes="events"
							/>
						</div>
					</div>
				</section>
			</div>
		)
	}

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Reviews - Niwot Inn &amp; Spa" />
		<div className="events-page">
			<ContentContainer />
		</div>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
